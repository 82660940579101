<template>
  <div class="contanter_deal">
    <new-header :config="headerConfig"></new-header>

    <div class="deal_header">
      <van-image round fit="cover" class="deal_header_img" :src="userData.user_avatar" />
      <div class="deai_info">
        <div>{{ userData.user_name }}</div>
      </div>
      <div class="header_right">
        <div class="right_btn2">{{ userData.shares }}股</div>
        <div>可转让工分</div>
      </div>
    </div>
    <div>
      <div class="selectBtn">
        <img v-if="showPicker" src="../../assets/bottom_j.png" />
        <img v-else src="../../assets/top_j.png" />
        <van-field readonly clickable name="picker" :value="select" @click="showPicker = true" />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
      </div>
    </div>
    <!-- list -->
    <div class="deal_list">
      <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
        <van-empty v-if="showList" description="暂无数据" />
        <div v-else v-for="(item, index) in list" :key="index">
          <div class="list_m">
            <div>
              <div class="size_s">{{ item.log_content }}</div>
              <!-- <div class="size_s">{{item.log_content.replce("股份","工分")}}</div> -->
              <div>{{ item.create_time }}</div>
            </div>
            <div>
              <div class="size_s">{{ item.shares_amount }}</div>
              <div>{{ item.total_shares }}</div>
            </div>
          </div>
          <van-divider />
        </div>
      </vue-loadmore>
    </div>
  </div>
</template>

<script>
import { dealDetails_api, shareorder_api } from "@/api/deal";
import newHeader from "@/components/newHeader";

export default {
  components: {
    newHeader
  },
  data() {
    return {
      headerConfig: {
        show: true,
        title: '工分明细',
      },
      userData: { user: {} },
      list: [],
      finished: false,
      pages: 0,
      query: { page: 1, limit: 10 },
      columns: ["收入", "支出", "全部"],
      select: "全部",
      showPicker: false,
      showList: false,
    };
  },
  created() {
    document.title = "工分明细";
    this.getHome();
    this.getList()
  },
  methods: {
    // 获取用户数据
    async getHome() {
      let res = await dealDetails_api()
      if (res.code == 0) {
        this.userData = res.data.user ? res.data.user : {}
      }
    },
    onConfirm(value, index) {
      this.select = value
      this.showPicker = false;

      let query = { limit: 10, page: 1 }
      this.list = []

      if (index == 0) query.increase = 1;
      if (index == 1) query.reduce = 1;
      this.query = query
      this.getList()
    },
    onRefresh(done) {
      this.list = [];
      this.query.page = 1;
      this.finished = false;
      this.getList();
      done();
    },
    onLoad(done) {
      if (this.query.page >= this.pages) {
        this.finished = true;
      } else {
        this.fetch();
      }
      done();
    },
    fetch() {
      this.query.page++;
      this.getList();
    },
    // 获取记录
    async getList() {
      let res = await shareorder_api(this.query)
      if (res.code == 0) {
        this.list = this.list.concat(res.data);
        this.pages = res.pagination.pages
        this.showList = this.list.length > 0 ? false : true
      }
    },
  }
};
</script>
<style scoped>
.contanter_deal {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  box-sizing: border-box;
}

.van-cell {
  background-color: transparent;
  color: #fff;
}

.size_s {
  font-size: 28px
}

.van-field>>>.van-field__control {
  border: none;
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-right: 15px;
  margin-top: 2px;
}

.van-picker-column {
  font-size: 30px;
}

.van-picker__cancel,
.van-picker__confirm {
  font-size: 25px;
}

.selectBtn {
  width: 176px;
  height: 60px;
  background: #333335;
  opacity: 1;
  border-radius: 10px;
  position: relative;
  margin: 20px 0 0 500px;
  display: flex;
  align-items: center;
}

.selectBtn img {
  width: 30px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 25px;
}

.deal_header {
  background-color: #333335;
  border-radius: 20px;
  height: 224px;
  width: 722px;
  margin: 10px auto;
  background-size: 100% 100%;
  padding: 46px 30px;
  box-sizing: border-box;
  display: flex;
  font-size: 25px;
  color: #fff;
  position: relative;
}

.deal_list {
  overflow-y: auto;
  height: 1000px
}

.deal_header_img {
  width: 100px;
  height: 100px;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.deai_info {
  margin-left: 20px;
}

.header_right {
  width: 231px;
  font-size: 25px;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 30px;
  top: 46px;
}

.header_right .right_btn2 {
  width: 231px;
  height: 69px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 35px;
  text-align: center;
  line-height: 69px;
  font-size: 35px;
  font-family: PingFang SC;
  color: #fff;
  margin-bottom: 17px;
}

.list_m {
  margin-top: 30px
}

.list_m {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  color: #2E2E30;
  font-size: 24px;
}

.list_m>div>div {
  margin-bottom: 20px
}
</style>
